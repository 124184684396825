<template>
  <v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      top
      color="bg-gradient-success"
    >
      {{ actionstatus }}
      <template v-slot:action="{ attrs }">
        <v-btn
          class="font-weight-bold"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Xааx
        </v-btn>
      </template>
    </v-snackbar>
    <div v-if="departments">
      <v-select
        :items="departments"
        return-object
        item-text="name2"
        item-value="id"
        v-model="selectedDepAndClass"
        @change="getCalendarData"
        label="Ангилалтsdfsdf сонгоx"
      >
      </v-select>
    </div>
    <v-row>
      <v-col>
        <v-btn color="orange" dark :to="{ name: 'LessonHuvaari' }" class="my-2">
          Буцаx
          <v-icon>mdi-edit</v-icon>
        </v-btn>
        <div
          class="d-flex flex-row"
          style="
            border: 1px solid #e0e0e0;
            border-bottom: none;
            padding-right: 15px;
          "
        >
          <div
            v-if="selectedDepAndClass"
            style="
              max-width: 60px;
              width: 100%;
              border-right: 1px solid #e0e0e0;
            "
          >
            <h1>
              {{ selectedDepAndClass.name2 }}
            </h1>
          </div>

          <v-col
            v-for="days in [
              'Даваа',
              'Мягмар',
              'Лхавга',
              'Пүрэв',
              'Баасан',
              'Бямба',
              'Ням',
            ]"
            class="text-center pt-3"
            :key="days"
            style="border-right: 1px solid #e0e0e0"
          >
            {{ days }}
          </v-col>
        </div>
        <v-sheet>
          <v-calendar
            ref="calendar"
            hide-header
            :first-interval="6"
            :interval-count="15"
            v-model="focus"
            color="primary"
            :events="events"
            :event-color="getEventColor"
            type="week"
            @click:event="showEvent"
            now="2022-08-01"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" flat class="pa-2">
              <v-btn outlined color="warning" @click="editItem()">
                Засаx
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn outlined color="error" class="ml-2" @click="deleteItem()">
                Устгаx
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card>
          </v-menu>
        </v-sheet>
        <v-dialog v-model="editDialog" max-width="400">
          <v-card>
            <v-card-title class="text-h5 px-4"> Xуваарь засаx </v-card-title>
            <v-card-actions class="pb-0">
              <v-select
                v-model="selectedEvent.name"
                :items="AvailableLesson"
                item-text="name"
                label="Xичээл сонгоx"
                outlined
                dense
              ></v-select>
            </v-card-actions>
            <v-card-actions class="pt-0 mt-n3">
              <v-select
                v-model="selectedEvent.selectedTime"
                :items="AvailableTimes"
                item-text="name"
                return-object
                label="Цаг сонгоx"
                outlined
                dense
              ></v-select>
            </v-card-actions>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                outlined
                @click="
                  ConfirmedEdit();
                  editDialog = false;
                "
              >
                Тийм
              </v-btn>

              <v-btn color="error" outlined @click="editDialog = false">
                Үгүй
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" max-width="400">
          <v-card>
            <v-card-title class="text-h6 px-4">
              {{ this.selectedEvent.name }} устгаx?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                outlined
                @click="
                  ConfirmDelete();
                  deleteDialog = false;
                "
              >
                Тийм
              </v-btn>

              <v-btn color="error" outlined @click="deleteDialog = false">
                Үгүй
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  data: () => ({
    selectedDepAndClass: null,
    departments: null,
    focus: "",
    events: [],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    actionstatus: null,
    snackbar: false,

    editDialog: false,
    deleteDialog: false,
    AvailableTimes: null,
    AvailableLesson: null,
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  computed: {
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
  },

  methods: {
    getCalendarData() {
      this.selectedDepAndClass.ref
        .collection("huvaariData-" + this.userData.school.currentYear)
        .onSnapshot((docs) => {
          this.events = [];
          docs.forEach((doc) => {
            let event = doc.data();
            event.ref = doc.ref;
            event.id = doc.id;
            this.events.push(event);
          });
        });
    },
    _getDepartments(startYear) {
      if (this.zzschool != null) {
        fb.db
          .collection(this.zzschool + "/departments")
          .where("startYear", "==", startYear)
          .orderBy("index", "desc")
          .onSnapshot((querySnapshot) => {
            this.departments = [];
            querySnapshot.forEach((doc) => {
              this.countOfPrograms = 0;
              let dep = doc.data();
              dep.id = doc.id;
              dep.ref = doc.ref;
              dep.classGroups = null;

              // this.departments.push(dep);
              dep.ref
                .collection("programs")
                .orderBy("name", "asc")
                .onSnapshot((querySnapshot) => {
                  dep.classGroups = [];
                  dep.numberOfStudents = 0;
                  querySnapshot.forEach(async (doc) => {
                    // doc.data() is never undefined for query doc snapshots
                    let prog = doc.data();
                    prog.id = doc.id;
                    prog.ref = doc.ref;
                    prog.dep = dep;
                    prog.name2 = dep.name + prog.name.toUpperCase();
                    if (this.selectedDepAndClass == null) {
                      this.selectedDepAndClass = prog;
                      prog.ref
                        .collection(
                          "huvaariData-" + this.userData.school.currentYear
                        )
                        .onSnapshot((docs) => {
                          this.events = [];
                          docs.forEach((doc) => {
                            let event = doc.data();
                            event.ref = doc.ref;
                            event.id = doc.id;
                            this.events.push(event);
                          });
                        });
                    }
                    this.departments.push(prog);
                  });
                });
            });
          });
      }
    },
    editItem() {
      this.editDialog = true;
    },
    ConfirmedEdit() {
      const mouse = this.toTime();
      const mouseEnd = this.toTimeEnd();
      const createStart = this.roundTime(mouse);
      const createEnd = this.roundTime(mouseEnd);
      this.updateData(createStart, createEnd);
    },
    updateData(createStart, createEnd) {
      this.selectedEvent.ref
        .update({
          name: this.selectedEvent.name,
          start: createStart,
          end: createEnd,
          selectedTime: this.selectedEvent.selectedTime,
        })
        .then(() => {
          this.actionstatus = "Successfully Edited";
          this.snackbar = true;
        });
    },

    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTimeEnd() {
      return new Date(
        this.selectedEvent.selectedEndYear,
        this.selectedEvent.selectedEndMonth - 1,
        this.selectedEvent.selectedEndDay,
        this.selectedEvent.selectedTime.endTime - 1,
        this.selectedEvent.selectedTime.endMin - 1
      ).getTime();
    },
    toTime() {
      return new Date(
        this.selectedEvent.selectedYear,
        this.selectedEvent.selectedMonth - 1,
        this.selectedEvent.selectedDay,
        this.selectedEvent.selectedTime.startTime - 1,
        this.selectedEvent.selectedTime.startMin - 1
      ).getTime();
    },
    deleteItem() {
      this.deleteDialog = true;
    },
    ConfirmDelete() {
      this.selectedEvent.ref.delete().then(() => {
        this.actionstatus = "Successfully Deleted";
        this.snackbar = true;
      });
    },

    getEventColor(event) {
      return event.color;
    },

    showEvent({ nativeEvent, event, timed }) {
      if (event && timed) {
        const open = () => {
          this.selectedEvent = event;
          this.selectedElement = nativeEvent.target;
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.selectedOpen = true))
          );
        };

        if (this.selectedOpen) {
          this.selectedOpen = false;
          requestAnimationFrame(() => requestAnimationFrame(() => open()));
        } else {
          open();
        }

        nativeEvent.stopPropagation();
      }
    },
  },

  created() {
    if (this.userData.school && this.userData.school.currentYear) {
      this._getDepartments(this.userData.school.currentYear);
      this.userData.school.ref.collection("xeelj").onSnapshot((docs) => {
        this.AvailableTimes = [];
        docs.forEach((doc) => {
          let eelj = doc.data();
          eelj.id = doc.id;
          eelj.ref = doc.ref;
          this.AvailableTimes.push(eelj);
        });
      });
      this.userData.school.ref.collection("lessons").onSnapshot((docs) => {
        this.AvailableLesson = [];
        docs.forEach((doc) => {
          let lesson = doc.data();
          lesson.ref = doc.ref;
          lesson.id = doc.id;
          this.AvailableLesson.push(lesson);
        });
      });
    }
  },
};
</script>
